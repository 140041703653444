<template>
	<div>
		<div class="main">
			<v-list>
				<v-list-item two-line router-link to="/change-password">
					<v-list-item-content>
						<v-list-item-title>Security</v-list-item-title>
						<v-list-item-subtitle>Change password</v-list-item-subtitle>
					</v-list-item-content>
				</v-list-item>
				<v-divider></v-divider>
			</v-list>
			
		</div>
	</div>
</template>

<script>

export default {
	name: 'Settings',

	data: () => ({
		title: "Settings"
	}),

	methods: {
		setTitle() {
			this.$emit('set-title', this.title)
		},
		getGoBackLink() {
			this.$emit('getGoBackLink', "");
		},
	},
	mounted() {
		if(!localStorage.getItem('tenantSlug')){
			this.$router.push('/choose-tenant');
			return
		}
		this.$emit("set-state", "default");
		this.setTitle(this.title)
		this.getGoBackLink()
	},
};
</script>

<style>

</style>
